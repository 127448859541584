import { UserIcon } from '@heroicons/react/24/outline';
import { useNavigate } from '@tanstack/react-router';

import { useUserSession } from '@/common/user-session/useUserSession';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/DropdownMenu.tsx';
import { cn } from '@/lib/utils';

export const UserProfileDropdown = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  const { user } = useUserSession();

  const doLogOut = () => {
    void navigate({
      to: '/logout',
    });
  };

  if (!user) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <span
          className={cn(
            'dark:text-white text-primary-foreground font-semibold text-lg p-1 flex items-center underline decoration-muted-foreground',
            className,
          )}
        >
          <UserIcon className={'mr-2 hidden size-5 sm:inline-block'} />
          {`${user?.firstName} ${user?.lastName}`}
        </span>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={doLogOut}>Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
