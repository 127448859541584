import { ReactNode } from 'react';

import { UserProfileDropdown } from '@/components/profile-dropdown/UserProfileDropdown.tsx';
import AppBar from '@/components/ui/AppBar.tsx';
import { Card } from '@/components/ui/Card.tsx';
import { cn } from '@/lib/utils';

type SingleCardLayoutProps = {
  children: ReactNode;
  maxWidth?: `max-w-${string}`;
  showBars?: boolean;
};

export const SingleCardLayout = ({
  children,
  maxWidth,
  showBars = true,
}: SingleCardLayoutProps) => {
  return (
    <>
      {showBars && (
        <>
          <AppBar endSlot={<UserProfileDropdown />} />
          <div className={'h-app-bar'} />
        </>
      )}
      <div className={cn('flex justify-center items-center', showBars ? 'pt-10 md:pt-28 px-4' : 'h-full')}>
        <div className={cn('max-w-3xl w-full', maxWidth)}>
          <Card className={cn('p-6 w-full', { 'mb-20': showBars })}>{children}</Card>
        </div>
      </div>
    </>
  );
};
